<template>
  <!-- eslint-disable max-len -->
  <svg
    ref="svgRef"
    width="156"
    height="156"
    viewBox="0 0 156 156"
    xmlns="http://www.w3.org/2000/svg"
    class="cta"
  >
    <defs v-if="text">
      <path
        :id="`circlePath ${text}`"
        class="path"
        d="M77.5 153.686C119.577 153.686 153.686 119.577 153.686 77.5C153.686 35.4234 119.577 1.31356 77.5 1.31356C35.4234 1.31356 1.31356 35.4234 1.31356 77.5C1.31356 119.577 35.4234 153.686 77.5 153.686ZM77.5 155C120.302 155 155 120.302 155 77.5C155 34.6979 120.302 0 77.5 0C34.6979 0 0 34.6979 0 77.5C0 120.302 34.6979 155 77.5 155Z"
      />
    </defs>
    <circle class="circle" cx="78" cy="78" r="76" />
    <g v-if="text" class="text-outer custom-text-outer">
      <circle cx="150" cy="100" r="75" fill="none" />
      <use :xlink:href="`#circlePath ${text}`" fill="none" />
      <g class="text-container">
        <text v-for="(n, i) in repeat" :key="n" class="text">
          <textPath
            text-anchor="start"
            :xlink:href="`#circlePath ${text}`"
            :startOffset="`${offset * i}%`"
          >
            {{ text }}
          </textPath>
        </text>
      </g>
    </g>
    <template v-else>
      <g class="text-outer">
        <g v-if="chrome.language === 'en'" class="text">
          <path
            d="M134.592 78.4873L141.849 78.964L141.67 81.6854C141.634 82.2429 141.506 82.7043 141.288 83.0695C141.069 83.4372 140.778 83.7063 140.416 83.8771C140.056 84.0503 139.644 84.1218 139.181 84.0914C138.716 84.0608 138.318 83.935 137.989 83.7141C137.662 83.4957 137.417 83.189 137.256 82.7941C137.097 82.3993 137.035 81.9231 137.072 81.3656L137.199 79.4273L138.291 79.499L138.175 81.2601C138.154 81.5861 138.181 81.856 138.257 82.0698C138.336 82.2837 138.459 82.446 138.627 82.5567C138.798 82.6699 139.012 82.735 139.269 82.7519C139.527 82.7688 139.75 82.7325 139.938 82.6428C140.129 82.5534 140.279 82.4078 140.387 82.2061C140.497 82.0046 140.563 81.7396 140.585 81.4113L140.664 80.2065L134.506 79.802L134.592 78.4873ZM137.634 82.4523L134.228 84.0329L134.324 82.5659L137.728 81.0172L137.634 82.4523Z"
          />
          <path
            d="M134.015 85.9433L141.159 87.3059L140.272 91.9523L139.188 91.7453L139.827 88.3931L137.888 88.0232L137.294 91.1347L136.209 90.9278L136.803 87.8163L134.853 87.4444L134.208 90.8245L133.123 90.6176L134.015 85.9433Z"
          />
          <path
            d="M132.462 93.27L132.896 91.9325L139.024 96.6128L138.522 98.1598L130.813 98.3535L131.247 97.0159L137.345 96.9529L137.363 96.8989L132.462 93.27ZM135.16 94.1942L133.976 97.8421L132.97 97.5155L134.154 93.8675L135.16 94.1942Z"
          />
          <path
            d="M129.273 101.781L130.342 99.5604L136.895 102.714L135.805 104.98C135.492 105.63 135.091 106.126 134.604 106.467C134.118 106.811 133.57 106.992 132.961 107.008C132.351 107.025 131.706 106.869 131.025 106.542C130.343 106.213 129.817 105.804 129.449 105.314C129.079 104.826 128.878 104.281 128.844 103.679C128.81 103.079 128.953 102.447 129.273 101.781ZM130.798 101.242L130.328 102.218C130.108 102.674 130.008 103.095 130.028 103.48C130.049 103.866 130.19 104.216 130.449 104.53C130.71 104.845 131.091 105.123 131.592 105.364C132.093 105.606 132.546 105.729 132.951 105.734C133.358 105.741 133.715 105.633 134.024 105.412C134.335 105.193 134.596 104.862 134.81 104.418L135.297 103.407L130.798 101.242Z"
          />
          <path
            d="M130.674 112.15L131.565 112.803L128.134 117.483L127.244 116.83L128.572 115.017L123.598 111.37L124.37 110.316L129.345 113.963L130.674 112.15Z"
          />
          <path
            d="M121 114.787L126.253 119.816L125.342 120.768L123.118 118.639L120.788 121.073L123.012 123.202L122.098 124.157L116.845 119.127L117.758 118.173L119.99 120.31L122.32 117.875L120.089 115.739L121 114.787Z"
          />
          <path
            d="M115.658 120.213L120.23 125.868L116.552 128.842L115.858 127.983L118.511 125.837L117.27 124.302L114.807 126.294L114.112 125.435L116.576 123.443L115.327 121.9L112.652 124.063L111.957 123.205L115.658 120.213Z"
          />
          <path
            d="M105.082 133.115L106.243 132.47C106.382 132.642 106.537 132.776 106.707 132.874C106.879 132.973 107.06 133.038 107.25 133.067C107.44 133.097 107.634 133.091 107.833 133.048C108.03 133.009 108.226 132.935 108.421 132.826C108.766 132.634 109.024 132.377 109.194 132.055C109.365 131.735 109.434 131.366 109.4 130.949C109.366 130.534 109.217 130.087 108.951 129.609C108.68 129.123 108.377 128.756 108.041 128.507C107.703 128.261 107.355 128.127 106.996 128.105C106.636 128.085 106.284 128.171 105.941 128.363C105.75 128.468 105.586 128.592 105.448 128.734C105.31 128.879 105.202 129.038 105.124 129.211C105.045 129.385 105 129.57 104.99 129.765C104.977 129.962 105.005 130.163 105.074 130.369L103.91 131.008C103.785 130.682 103.722 130.346 103.719 130.001C103.715 129.657 103.772 129.319 103.891 128.987C104.011 128.658 104.194 128.35 104.441 128.063C104.689 127.779 105.003 127.531 105.381 127.321C105.94 127.01 106.51 126.862 107.093 126.876C107.675 126.891 108.228 127.072 108.752 127.42C109.277 127.767 109.728 128.283 110.108 128.965C110.489 129.65 110.688 130.306 110.704 130.934C110.721 131.564 110.582 132.13 110.288 132.633C109.993 133.135 109.569 133.541 109.014 133.849C108.66 134.046 108.304 134.18 107.945 134.25C107.585 134.32 107.233 134.324 106.888 134.264C106.544 134.207 106.219 134.082 105.911 133.89C105.603 133.701 105.327 133.443 105.082 133.115Z"
          />
          <path
            d="M100.675 129.814L101.984 129.3L102.257 137.006L100.743 137.6L95.6996 131.766L97.0087 131.253L100.929 135.925L100.982 135.904L100.675 129.814ZM101.673 132.485L98.1033 133.886L97.7167 132.901L101.287 131.5L101.673 132.485Z"
          />
          <path
            d="M91.9118 138.212C92.0194 138.505 92.2136 138.705 92.4945 138.813C92.773 138.921 93.1017 138.928 93.4806 138.833C93.747 138.766 93.9654 138.669 94.1359 138.54C94.3063 138.412 94.4248 138.267 94.4913 138.104C94.5578 137.94 94.57 137.77 94.528 137.593C94.4911 137.446 94.4259 137.327 94.3322 137.235C94.2363 137.145 94.1202 137.076 93.9841 137.03C93.8486 136.986 93.7033 136.956 93.5483 136.941C93.3932 136.927 93.239 136.92 93.0857 136.922L92.3829 136.922C92.101 136.927 91.824 136.908 91.5518 136.864C91.2773 136.82 91.0223 136.742 90.7868 136.631C90.549 136.519 90.3442 136.365 90.1724 136.166C90.0006 135.967 89.8764 135.715 89.7998 135.41C89.6962 134.997 89.7106 134.606 89.8429 134.239C89.9759 133.873 90.22 133.552 90.5752 133.275C90.9288 133.001 91.388 132.793 91.9529 132.652C92.5017 132.514 92.9995 132.48 93.4463 132.548C93.8907 132.617 94.2675 132.786 94.5766 133.056C94.8834 133.325 95.1047 133.692 95.2405 134.156L93.9833 134.471C93.9033 134.23 93.7763 134.045 93.6022 133.915C93.4281 133.785 93.2242 133.707 92.9905 133.68C92.7545 133.654 92.5033 133.674 92.237 133.741C91.9591 133.811 91.7261 133.913 91.5378 134.048C91.3478 134.186 91.2119 134.345 91.1299 134.524C91.0485 134.705 91.032 134.897 91.0803 135.1C91.1287 135.283 91.2206 135.421 91.3562 135.514C91.4924 135.609 91.6658 135.673 91.8765 135.706C92.0856 135.741 92.3244 135.759 92.5932 135.76L93.4476 135.766C94.066 135.769 94.5819 135.881 94.9955 136.099C95.4073 136.32 95.6766 136.684 95.8032 137.189C95.9075 137.604 95.8862 137.997 95.7394 138.365C95.5904 138.735 95.3423 139.054 94.9951 139.325C94.6485 139.597 94.2283 139.795 93.7346 139.919C93.234 140.044 92.7735 140.067 92.3531 139.987C91.9311 139.91 91.5735 139.744 91.2805 139.491C90.9881 139.239 90.7886 138.916 90.6821 138.52L91.9118 138.212Z"
          />
          <path
            d="M88.0429 133.672L88.9929 140.883L84.3033 141.5L84.1591 140.405L87.5425 139.96L87.2846 138.002L84.1441 138.416L83.9998 137.321L87.1403 136.907L86.881 134.939L83.4694 135.389L83.3252 134.294L88.0429 133.672Z"
          />
          <path
            d="M74.2879 139.677C74.309 139.988 74.4393 140.235 74.6786 140.417C74.9156 140.599 75.2293 140.698 75.6196 140.713C75.894 140.723 76.131 140.691 76.3306 140.616C76.5301 140.541 76.6847 140.434 76.7942 140.296C76.9038 140.158 76.9633 139.998 76.9727 139.816C76.9785 139.665 76.9493 139.532 76.885 139.418C76.8183 139.304 76.7262 139.206 76.6085 139.123C76.4908 139.043 76.3596 138.974 76.215 138.916C76.0703 138.858 75.9241 138.809 75.7765 138.768L75.1017 138.571C74.8298 138.497 74.5693 138.4 74.3204 138.282C74.0691 138.163 73.8461 138.017 73.6515 137.843C73.4544 137.67 73.3013 137.464 73.192 137.225C73.0828 136.986 73.0342 136.71 73.0464 136.395C73.0628 135.969 73.1861 135.598 73.4163 135.283C73.6463 134.969 73.9707 134.73 74.3894 134.563C74.8056 134.399 75.3046 134.329 75.8866 134.351C76.452 134.373 76.9395 134.479 77.3491 134.671C77.7563 134.862 78.0705 135.13 78.2918 135.475C78.5106 135.819 78.6202 136.234 78.6205 136.717L77.3253 136.667C77.3161 136.413 77.2462 136.199 77.1155 136.026C76.9848 135.853 76.811 135.721 76.5942 135.629C76.375 135.538 76.1282 135.487 75.8538 135.476C75.5676 135.465 75.3151 135.498 75.0966 135.575C74.8756 135.654 74.7006 135.768 74.5716 135.917C74.4426 136.069 74.3729 136.248 74.3625 136.457C74.3575 136.646 74.4071 136.804 74.5111 136.931C74.6151 137.061 74.7637 137.171 74.9568 137.261C75.1475 137.354 75.3717 137.438 75.6294 137.515L76.448 137.76C77.0405 137.936 77.5047 138.188 77.8404 138.513C78.1736 138.841 78.3302 139.266 78.3101 139.786C78.2936 140.214 78.1632 140.585 77.9189 140.897C77.6723 141.21 77.3445 141.447 76.9354 141.609C76.5263 141.774 76.0675 141.846 75.5589 141.826C75.0431 141.806 74.5948 141.699 74.2137 141.504C73.8303 141.312 73.5335 141.053 73.3233 140.727C73.1131 140.404 73.0123 140.038 73.021 139.628L74.2879 139.677Z"
          />
          <path
            d="M71.1976 140.396L71.0198 141.486L65.2929 140.552L65.4707 139.462L67.6892 139.824L68.6822 133.736L69.972 133.946L68.979 140.034L71.1976 140.396Z"
          />
          <path
            d="M57.9366 138.567L56.6775 138.179L58.0758 133.638C58.2291 133.14 58.4815 132.739 58.8332 132.433C59.1826 132.127 59.6016 131.933 60.0902 131.851C60.5781 131.77 61.1049 131.817 61.6705 131.992C62.2384 132.166 62.7015 132.424 63.0598 132.765C63.4174 133.108 63.6546 133.504 63.7713 133.954C63.888 134.403 63.8697 134.877 63.7164 135.375L62.3181 139.916L61.059 139.528L62.4249 135.092C62.5141 134.803 62.5301 134.525 62.4731 134.26C62.4138 133.994 62.2875 133.761 62.0942 133.56C61.9001 133.362 61.6436 133.213 61.3246 133.115C61.0056 133.017 60.71 132.995 60.4379 133.05C60.1629 133.107 59.9272 133.228 59.7309 133.416C59.5345 133.603 59.3917 133.841 59.3025 134.131L57.9366 138.567Z"
          />
          <path
            d="M55.0464 129.647L57.2865 130.675L54.2549 137.285L51.9696 136.237C51.3132 135.936 50.8101 135.545 50.4602 135.065C50.1072 134.585 49.9167 134.041 49.8888 133.431C49.8608 132.822 50.0043 132.174 50.3191 131.488C50.6349 130.799 51.0343 130.266 51.5173 129.889C51.9982 129.51 52.5393 129.299 53.1406 129.254C53.7397 129.208 54.375 129.339 55.0464 129.647ZM55.6138 131.162L54.6293 130.71C54.1688 130.499 53.7462 130.407 53.3615 130.433C52.9758 130.462 52.6285 130.609 52.3196 130.874C52.0097 131.141 51.7388 131.527 51.5069 132.033C51.275 132.538 51.1601 132.993 51.162 133.398C51.163 133.805 51.2773 134.161 51.5047 134.465C51.729 134.771 52.0649 135.027 52.5125 135.232L53.5326 135.7L55.6138 131.162Z"
          />
          <path
            d="M46.9795 133.859L45.7241 133.06L45.9502 129.391L45.8903 129.353L42.6621 131.112L41.4068 130.313L46.0866 127.886L47.543 125.597L48.6515 126.303L47.1951 128.592L46.9795 133.859Z"
          />
          <path
            d="M21.3268 77.0219L14.0697 76.5453L14.2484 73.8239C14.2851 73.2664 14.4126 72.805 14.6312 72.4397C14.8499 72.0721 15.1404 71.8029 15.5027 71.6322C15.8629 71.4589 16.2745 71.3875 16.7375 71.4179C17.2029 71.4485 17.6003 71.5742 17.9298 71.7952C18.2571 72.0136 18.5015 72.3203 18.663 72.7152C18.8221 73.11 18.8834 73.5862 18.8467 74.1437L18.7194 76.082L17.628 76.0103L17.7437 74.2492C17.7651 73.9232 17.7378 73.6533 17.6617 73.4395C17.5832 73.2256 17.4598 73.0633 17.2915 72.9526C17.121 72.8393 16.907 72.7743 16.6495 72.7574C16.392 72.7404 16.169 72.7768 15.9804 72.8664C15.7895 72.9559 15.6399 73.1015 15.5318 73.3032C15.4213 73.5047 15.3552 73.7696 15.3337 74.098L15.2545 75.3028L21.4131 75.7073L21.3268 77.0219ZM18.2847 73.0569L21.691 71.4764L21.5947 72.9434L18.1904 74.492L18.2847 73.0569Z"
          />
          <path
            d="M21.9039 69.566L14.76 68.2034L15.6462 63.557L16.7311 63.7639L16.0917 67.1161L18.0312 67.4861L18.6246 64.3746L19.7095 64.5815L19.116 67.693L21.0659 68.0649L21.7107 64.6848L22.7955 64.8917L21.9039 69.566Z"
          />
          <path
            d="M23.4564 62.2392L23.0223 63.5768L16.8951 58.8965L17.3972 57.3495L25.1062 57.1558L24.6721 58.4934L18.5735 58.5563L18.556 58.6104L23.4564 62.2392ZM20.7583 61.3151L21.9422 57.6672L22.9488 57.9938L21.7649 61.6417L20.7583 61.3151Z"
          />
          <path
            d="M26.6456 53.7282L25.5768 55.9489L19.0236 52.7949L20.1139 50.5294C20.427 49.8788 20.8272 49.383 21.3144 49.0421C21.8006 48.6981 22.3484 48.5178 22.9581 48.5011C23.5678 48.4845 24.2128 48.64 24.8933 48.9675C25.576 49.296 26.1015 49.7053 26.47 50.1953C26.8395 50.6831 27.0409 51.2281 27.0744 51.83C27.1088 52.4299 26.9659 53.0626 26.6456 53.7282ZM25.121 54.2674L25.5907 53.2914C25.8104 52.8349 25.9105 52.4141 25.8909 52.029C25.8692 51.6428 25.7289 51.2929 25.4701 50.9791C25.2091 50.6643 24.8279 50.3863 24.3266 50.145C23.8253 49.9038 23.3724 49.7804 22.9678 49.7748C22.5611 49.7682 22.2032 49.8758 21.8942 50.0976C21.5841 50.3161 21.3222 50.6473 21.1087 51.091L20.6221 52.1021L25.121 54.2674Z"
          />
          <path
            d="M25.2447 43.3592L24.354 42.7063L27.7845 38.0264L28.6752 38.6793L27.3463 40.4923L32.3212 44.139L31.5486 45.193L26.5737 41.5463L25.2447 43.3592Z"
          />
          <path
            d="M34.9189 40.7221L29.6655 35.6929L30.5765 34.7412L32.8005 36.8703L35.131 34.4359L32.907 32.3068L33.8205 31.3526L39.074 36.3819L38.1605 37.3361L35.9288 35.1997L33.5983 37.634L35.83 39.7705L34.9189 40.7221Z"
          />
          <path
            d="M40.2612 35.2967L35.6884 29.6414L39.3666 26.6673L40.061 27.5261L37.4073 29.6718L38.6487 31.2071L41.1119 29.2155L41.8063 30.0742L39.3431 32.0659L40.5912 33.6095L43.267 31.4459L43.9614 32.3047L40.2612 35.2967Z"
          />
          <path
            d="M50.8364 22.3938L49.6758 23.0396C49.5368 22.8677 49.382 22.733 49.2113 22.6357C49.0395 22.5362 48.8586 22.4716 48.6686 22.4419C48.4787 22.4121 48.2845 22.4185 48.0861 22.4612C47.8886 22.5007 47.6926 22.5745 47.4981 22.6827C47.1527 22.875 46.8948 23.1322 46.7247 23.4545C46.5533 23.7747 46.4848 24.1433 46.5192 24.5604C46.5523 24.9755 46.7019 25.4219 46.9678 25.8998C47.2383 26.3859 47.5417 26.7535 47.8781 27.0025C48.2154 27.2484 48.5636 27.3824 48.9228 27.4047C49.2829 27.4238 49.6346 27.3377 49.978 27.1467C50.1684 27.0407 50.3325 26.9169 50.4703 26.7752C50.6091 26.6302 50.7171 26.4713 50.7942 26.2983C50.8734 26.1242 50.9183 25.9394 50.9287 25.7439C50.9412 25.5473 50.9133 25.3461 50.8448 25.1404L52.0088 24.5008C52.1334 24.827 52.197 25.1628 52.1996 25.5081C52.2042 25.8524 52.147 26.1903 52.0281 26.522C51.9081 26.8516 51.7246 27.1596 51.4776 27.446C51.2295 27.7304 50.9161 27.9779 50.5375 28.1886C49.979 28.4994 49.4085 28.6475 48.826 28.633C48.2435 28.6185 47.6903 28.4373 47.1662 28.0895C46.6422 27.7417 46.1902 27.2265 45.8103 26.5438C45.4293 25.8591 45.2308 25.2029 45.2147 24.5751C45.1975 23.9453 45.3362 23.3791 45.6309 22.8765C45.9256 22.3738 46.3501 21.9683 46.9045 21.6598C47.2583 21.4629 47.6148 21.3295 47.9742 21.2596C48.3335 21.1897 48.6857 21.1847 49.0306 21.2447C49.3744 21.3027 49.7 21.4277 50.0074 21.6197C50.3156 21.8085 50.592 22.0665 50.8364 22.3938Z"
          />
          <path
            d="M55.244 25.6953L53.9349 26.2091L53.6616 18.5036L55.1756 17.9095L60.2191 23.743L58.91 24.2567L54.9899 19.5846L54.937 19.6053L55.244 25.6953ZM54.2453 23.024L57.8154 21.623L58.202 22.6081L54.6318 24.0091L54.2453 23.024Z"
          />
          <path
            d="M64.0069 17.2971C63.8993 17.0044 63.7051 16.8041 63.4242 16.6963C63.1457 16.588 62.817 16.5813 62.4381 16.6763C62.1717 16.7431 61.9533 16.8406 61.7828 16.9688C61.6124 17.0969 61.4939 17.2425 61.4274 17.4057C61.3609 17.5688 61.3487 17.739 61.3907 17.9164C61.4276 18.0634 61.4928 18.1825 61.5865 18.2737C61.6824 18.3644 61.7985 18.4329 61.9346 18.4793C62.0701 18.5235 62.2154 18.5529 62.3704 18.5678C62.5255 18.5826 62.6797 18.5891 62.833 18.5872L63.5358 18.5868C63.8177 18.582 64.0947 18.6016 64.3669 18.6456C64.6414 18.6891 64.8964 18.7667 65.1319 18.8785C65.3697 18.9898 65.5745 19.1447 65.7463 19.3432C65.9181 19.5418 66.0423 19.7937 66.1189 20.0992C66.2225 20.5125 66.2081 20.903 66.0758 21.2705C65.9428 21.6358 65.6987 21.9569 65.3435 22.2339C64.9899 22.5081 64.5307 22.7159 63.9658 22.8576C63.417 22.9952 62.9192 23.0297 62.4724 22.9611C62.028 22.8919 61.6512 22.7227 61.3421 22.4537C61.0353 22.184 60.814 21.8172 60.6782 21.3534L61.9354 21.0382C62.0154 21.2793 62.1424 21.4647 62.3165 21.5943C62.4906 21.724 62.6945 21.8022 62.9282 21.829C63.1642 21.8553 63.4154 21.835 63.6817 21.7682C63.9596 21.6986 64.1926 21.5962 64.3809 21.4611C64.5709 21.3232 64.7068 21.1647 64.7888 20.9854C64.8702 20.804 64.8868 20.6119 64.8384 20.4092C64.79 20.2261 64.6981 20.0881 64.5625 19.9951C64.4263 19.8999 64.2529 19.836 64.0422 19.8034C63.8331 19.768 63.5943 19.7497 63.3255 19.7488L62.4711 19.7433C61.8527 19.7397 61.3368 19.6287 60.9232 19.4102C60.5114 19.1888 60.2421 18.8256 60.1155 18.3204C60.0112 17.9047 60.0325 17.5125 60.1793 17.1438C60.3283 16.7745 60.5764 16.4548 60.9236 16.1847C61.2702 15.9123 61.6904 15.7142 62.1841 15.5904C62.6847 15.4649 63.1452 15.4422 63.5656 15.5223C63.9876 15.5996 64.3452 15.765 64.6382 16.0186C64.9306 16.2699 65.1301 16.5933 65.2366 16.9888L64.0069 17.2971Z"
          />
          <path
            d="M67.8758 21.8371L66.9258 14.6267L71.6154 14.0088L71.7596 15.1038L68.3762 15.5496L68.6341 17.5071L71.7746 17.0933L71.9189 18.1882L68.7784 18.602L69.0377 20.5701L72.4493 20.1206L72.5935 21.2155L67.8758 21.8371Z"
          />
          <path
            d="M81.6308 15.8324C81.6097 15.5212 81.4794 15.2746 81.2401 15.0924C81.0031 14.9103 80.6894 14.8117 80.2991 14.7966C80.0247 14.786 79.7877 14.8183 79.5881 14.8935C79.3886 14.9688 79.234 15.0753 79.1245 15.2132C79.0149 15.3512 78.9554 15.5112 78.946 15.6932C78.9402 15.8446 78.9694 15.9772 79.0337 16.0911C79.1004 16.205 79.1925 16.3033 79.3102 16.3861C79.4279 16.4664 79.5591 16.5355 79.7037 16.5932C79.8484 16.6509 79.9946 16.7003 80.1422 16.7416L80.817 16.9382C81.0889 17.0127 81.3494 17.1092 81.5983 17.2278C81.8496 17.3465 82.0726 17.4925 82.2672 17.6659C82.4643 17.8393 82.6174 18.0454 82.7267 18.2842C82.8359 18.523 82.8845 18.7997 82.8723 19.1143C82.8559 19.5401 82.7326 19.9109 82.5024 20.2266C82.2724 20.5399 81.948 20.7797 81.5293 20.946C81.1131 21.1099 80.6141 21.1807 80.0321 21.1582C79.4667 21.1364 78.9792 21.0299 78.5696 20.8388C78.1624 20.6477 77.8482 20.3797 77.6269 20.0348C77.4081 19.6899 77.2985 19.2758 77.2982 18.7925L78.5934 18.8425C78.6026 19.0963 78.6725 19.3099 78.8032 19.4831C78.9339 19.6564 79.1077 19.7887 79.3245 19.88C79.5437 19.9714 79.7905 20.0224 80.0649 20.0329C80.3511 20.044 80.6036 20.0111 80.8221 19.9343C81.0431 19.8551 81.2181 19.7411 81.3471 19.592C81.4761 19.4407 81.5458 19.2609 81.5562 19.0528C81.5612 18.8635 81.5116 18.7052 81.4076 18.578C81.3036 18.4484 81.155 18.3384 80.9619 18.248C80.7712 18.1554 80.547 18.0709 80.2893 17.9946L79.4707 17.7498C78.8782 17.5729 78.414 17.3216 78.0783 16.9959C77.7451 16.668 77.5885 16.2438 77.6086 15.7233C77.6252 15.2951 77.7555 14.9247 77.9998 14.6119C78.2464 14.2992 78.5742 14.0619 78.9833 13.9C79.3924 13.7357 79.8512 13.6634 80.3598 13.6831C80.8756 13.703 81.3239 13.8103 81.705 14.0051C82.0884 14.1976 82.3852 14.4566 82.5954 14.7822C82.8056 15.1054 82.9064 15.4718 82.8977 15.8813L81.6308 15.8324Z"
          />
          <path
            d="M84.7211 15.1132L84.8989 14.0232L90.6258 14.9573L90.448 16.0473L88.2295 15.6854L87.2365 21.7733L85.9467 21.5629L86.9397 15.4751L84.7211 15.1132Z"
          />
          <path
            d="M97.9821 16.9426L99.2412 17.3303L97.8429 21.8713C97.6896 22.3691 97.4372 22.7706 97.0855 23.076C96.7361 23.3821 96.3171 23.5764 95.8285 23.6588C95.3406 23.7389 94.8139 23.6919 94.2482 23.5177C93.6803 23.3428 93.2172 23.085 92.8589 22.7443C92.5013 22.4014 92.2641 22.0051 92.1474 21.5555C92.0307 21.1059 92.049 20.6322 92.2023 20.1344L93.6006 15.5934L94.8597 15.9811L93.4938 20.4169C93.4046 20.7065 93.3886 20.984 93.4456 21.2492C93.5049 21.5152 93.6312 21.7486 93.8245 21.9493C94.0186 22.1477 94.2751 22.2961 94.5941 22.3943C94.9131 22.4926 95.2087 22.5142 95.4808 22.4593C95.7558 22.4028 95.9915 22.2809 96.1878 22.0936C96.3842 21.9064 96.527 21.668 96.6162 21.3784L97.9821 16.9426Z"
          />
          <path
            d="M100.872 25.8619L98.6322 24.8346L101.664 18.2239L103.949 19.2719C104.605 19.5729 105.109 19.9638 105.458 20.4446C105.811 20.9242 106.002 21.4687 106.03 22.0779C106.058 22.6872 105.914 23.335 105.6 24.0215C105.284 24.7101 104.884 25.2432 104.401 25.6207C103.92 25.9992 103.379 26.2107 102.778 26.2554C102.179 26.301 101.544 26.1698 100.872 25.8619ZM100.305 24.3477L101.289 24.7991C101.75 25.0103 102.173 25.1025 102.557 25.0758C102.943 25.0469 103.29 24.9002 103.599 24.6355C103.909 24.3687 104.18 23.9825 104.412 23.4767C104.644 22.971 104.759 22.5159 104.757 22.1113C104.756 21.7045 104.641 21.3487 104.414 21.0439C104.19 20.7379 103.854 20.4822 103.406 20.277L102.386 19.8092L100.305 24.3477Z"
          />
          <path
            d="M108.939 21.6503L110.195 22.449L109.968 26.1185L110.028 26.1567L113.257 24.3973L114.512 25.196L109.832 27.6228L108.376 29.9118L107.267 29.2065L108.724 26.9174L108.939 21.6503Z"
          />
        </g>
        <g v-else class="text">
          <path
            d="M134.566 77.5534L141.828 77.9461L141.757 79.2616L135.598 78.9286L135.425 82.127L134.322 82.0674L134.566 77.5534Z"
          />
          <path
            d="M141.08 86.6481L133.885 85.5875L134.077 84.2841L141.272 85.3447L141.08 86.6481Z"
          />
          <path
            d="M133.592 87.8715L140.647 89.6402L139.983 92.2856C139.848 92.8275 139.639 93.2586 139.359 93.5788C139.078 93.9013 138.744 94.1141 138.357 94.2172C137.972 94.3232 137.554 94.3197 137.104 94.2069C136.651 94.0935 136.283 93.8986 135.998 93.6222C135.715 93.3487 135.53 93.0032 135.442 92.5857C135.356 92.1688 135.381 91.6894 135.517 91.1474L135.989 89.2633L137.05 89.5293L136.621 91.2412C136.541 91.5581 136.52 91.8285 136.557 92.0525C136.595 92.277 136.688 92.4588 136.834 92.5978C136.981 92.7398 137.18 92.8421 137.43 92.9049C137.681 92.9676 137.906 92.9718 138.108 92.9174C138.312 92.8635 138.485 92.7471 138.628 92.568C138.772 92.3896 138.885 92.1407 138.965 91.8215L139.259 90.6504L133.272 89.1495L133.592 87.8715ZM135.875 92.3173L132.241 93.2622L132.598 91.8362L136.225 90.9222L135.875 92.3173Z"
          />
          <path
            d="M131.622 95.2838L138.393 97.9405L136.665 102.344L135.637 101.94L136.883 98.7635L135.045 98.0422L133.888 100.991L132.86 100.587L134.017 97.6388L132.169 96.9136L130.912 100.117L129.884 99.7134L131.622 95.2838Z"
          />
          <path
            d="M127.878 103.924L134.112 107.669L133.434 108.799L128.146 105.622L126.497 108.368L125.55 107.799L127.878 103.924Z"
          />
          <path
            d="M129.585 114.723L128.997 114.299C128.828 114.177 128.676 114.017 128.541 113.82C128.406 113.627 128.296 113.415 128.212 113.184C128.129 112.955 128.08 112.727 128.066 112.501L128.729 112.244C128.812 112.464 128.917 112.68 129.045 112.892C129.174 113.105 129.356 113.296 129.59 113.465L130.183 113.893L129.585 114.723Z"
          />
          <path
            d="M122.756 111.807L128.293 116.523L125.226 120.124L124.386 119.408L126.598 116.81L125.095 115.53L123.041 117.941L122.2 117.225L124.254 114.813L122.743 113.526L120.512 116.146L119.671 115.43L122.756 111.807Z"
          />
          <path
            d="M122.814 121.171L123.557 121.988L119.265 125.893L118.521 125.076L120.184 123.563L116.033 119L117 118.121L121.151 122.684L122.814 121.171Z"
          />
          <path
            d="M113.19 130.428L112.108 131.18L109.398 127.277C109.101 126.849 108.941 126.403 108.918 125.938C108.892 125.474 108.998 125.024 109.235 124.589C109.473 124.156 109.835 123.77 110.321 123.432C110.809 123.094 111.298 122.888 111.787 122.817C112.278 122.747 112.736 122.805 113.162 122.991C113.587 123.176 113.949 123.483 114.246 123.911L116.956 127.813L115.874 128.565L113.227 124.753C113.054 124.504 112.845 124.32 112.601 124.201C112.356 124.083 112.094 124.039 111.817 124.067C111.541 124.097 111.266 124.208 110.992 124.398C110.717 124.588 110.518 124.808 110.393 125.056C110.268 125.307 110.218 125.567 110.245 125.838C110.271 126.108 110.37 126.367 110.543 126.616L113.19 130.428Z"
          />
          <path
            d="M104.374 127.202L106.566 126.076L109.889 132.545L107.652 133.694C107.01 134.024 106.392 134.178 105.798 134.156C105.202 134.137 104.657 133.95 104.161 133.596C103.664 133.241 103.244 132.728 102.899 132.056C102.552 131.382 102.38 130.739 102.382 130.126C102.382 129.514 102.55 128.958 102.886 128.457C103.221 127.958 103.717 127.54 104.374 127.202ZM105.915 127.692L104.951 128.187C104.501 128.419 104.167 128.694 103.95 129.013C103.735 129.334 103.635 129.698 103.652 130.104C103.671 130.512 103.807 130.964 104.061 131.459C104.315 131.954 104.602 132.326 104.921 132.574C105.242 132.825 105.592 132.955 105.972 132.964C106.351 132.977 106.76 132.871 107.198 132.646L108.196 132.133L105.915 127.692Z"
          />
          <path
            d="M99.7809 129.437L102.259 136.275L97.8119 137.887L97.4356 136.848L100.644 135.685L99.9712 133.829L96.9932 134.908L96.6169 133.87L99.5949 132.791L98.9185 130.925L95.6834 132.097L95.3071 131.059L99.7809 129.437Z"
          />
          <path
            d="M88.4179 132.763L90.8455 132.338L92.0987 139.502L89.6221 139.936C88.9108 140.06 88.2747 140.023 87.7138 139.826C87.151 139.631 86.6853 139.291 86.3167 138.805C85.9481 138.319 85.6988 137.704 85.5687 136.96C85.4381 136.214 85.4647 135.548 85.6484 134.964C85.8297 134.379 86.1553 133.898 86.6252 133.52C87.0927 133.143 87.6903 132.89 88.4179 132.763ZM89.7442 133.688L88.6773 133.875C88.1782 133.962 87.7779 134.126 87.4762 134.366C87.1749 134.609 86.9722 134.927 86.8679 135.32C86.7641 135.715 86.7601 136.187 86.8559 136.735C86.9518 137.283 87.1153 137.723 87.3464 138.055C87.578 138.39 87.8738 138.618 88.2341 138.74C88.5924 138.865 89.014 138.885 89.4991 138.8L90.6045 138.607L89.7442 133.688Z"
          />
          <path
            d="M83.3777 133.53L83.7144 140.795L78.9894 141.014L78.9382 139.911L82.3472 139.753L82.2558 137.781L79.0916 137.927L79.0405 136.824L82.2047 136.677L82.1128 134.694L78.6755 134.854L78.6243 133.751L83.3777 133.53Z"
          />
          <path
            d="M66.9581 137.619L68.2749 137.792C68.284 138.013 68.328 138.213 68.407 138.393C68.4856 138.576 68.5923 138.735 68.7271 138.872C68.8618 139.01 69.0216 139.12 69.2063 139.204C69.3884 139.29 69.5898 139.348 69.8104 139.377C70.2024 139.429 70.5627 139.376 70.8915 139.219C71.2199 139.064 71.4947 138.808 71.7158 138.453C71.9367 138.1 72.0828 137.653 72.1542 137.11C72.2269 136.559 72.2025 136.083 72.0809 135.682C71.9567 135.284 71.7571 134.969 71.482 134.737C71.2043 134.507 70.8706 134.366 70.4809 134.315C70.265 134.286 70.0594 134.288 69.8643 134.319C69.6664 134.353 69.4849 134.416 69.3199 134.509C69.1525 134.602 69.0063 134.723 68.8814 134.874C68.7541 135.024 68.6566 135.203 68.5889 135.409L67.2731 135.228C67.3675 134.892 67.5167 134.584 67.7206 134.306C67.9221 134.026 68.1695 133.789 68.4626 133.594C68.7555 133.401 69.0865 133.263 69.4556 133.18C69.8243 133.1 70.2235 133.088 70.653 133.145C71.2867 133.228 71.8331 133.449 72.292 133.808C72.751 134.167 73.0871 134.642 73.3005 135.234C73.5138 135.826 73.5695 136.509 73.4675 137.283C73.3651 138.06 73.1332 138.706 72.7719 139.219C72.4102 139.735 71.9612 140.107 71.425 140.335C70.8887 140.563 70.3061 140.635 69.677 140.552C69.2757 140.499 68.9099 140.394 68.5797 140.236C68.2495 140.077 67.9639 139.872 67.7227 139.618C67.4812 139.366 67.2944 139.072 67.1621 138.734C67.0272 138.399 66.9592 138.027 66.9581 137.619Z"
          />
          <path
            d="M65.3947 132.355L66.752 132.723L62.3785 139.073L60.8087 138.647L60.2363 130.957L61.5936 131.325L61.9563 137.413L62.0111 137.428L65.3947 132.355ZM64.6043 135.095L60.9026 134.092L61.1794 133.071L64.8811 134.074L64.6043 135.095Z"
          />
          <path
            d="M53.3725 133.901C53.2856 134.201 53.3233 134.477 53.4856 134.731C53.6457 134.983 53.9064 135.183 54.2679 135.331C54.522 135.435 54.7557 135.486 54.9689 135.484C55.1821 135.482 55.3639 135.435 55.5141 135.343C55.6644 135.251 55.7752 135.121 55.8465 134.954C55.9039 134.813 55.922 134.679 55.9007 134.55C55.8771 134.42 55.8243 134.296 55.7422 134.178C55.6592 134.062 55.5597 133.952 55.4437 133.848C55.3276 133.744 55.2073 133.648 55.0827 133.558L54.5165 133.142C54.2866 132.979 54.0751 132.799 53.882 132.602C53.6867 132.404 53.5274 132.191 53.404 131.961C53.2785 131.73 53.2054 131.484 53.1847 131.222C53.1641 130.961 53.2135 130.684 53.3329 130.393C53.4944 129.998 53.7375 129.693 54.062 129.475C54.3857 129.26 54.7726 129.146 55.2229 129.133C55.6701 129.122 56.1632 129.227 56.702 129.448C57.2256 129.662 57.6469 129.93 57.9661 130.25C58.283 130.569 58.4861 130.929 58.5755 131.329C58.6627 131.728 58.6235 132.154 58.4579 132.608L57.2586 132.117C57.3371 131.875 57.3447 131.65 57.2814 131.443C57.2181 131.235 57.1002 131.051 56.9279 130.891C56.7534 130.73 56.5391 130.598 56.285 130.493C56.02 130.385 55.7716 130.329 55.5399 130.326C55.3052 130.325 55.1017 130.372 54.9294 130.468C54.7563 130.565 54.6291 130.71 54.5479 130.902C54.4783 131.078 54.4705 131.244 54.5246 131.399C54.5778 131.557 54.6796 131.711 54.83 131.862C54.9773 132.015 55.1588 132.171 55.3747 132.331L56.0595 132.842C56.5554 133.211 56.9051 133.607 57.1086 134.028C57.309 134.45 57.3105 134.902 57.113 135.384C56.9505 135.781 56.7008 136.084 56.3641 136.294C56.0251 136.503 55.6358 136.613 55.196 136.625C54.7554 136.639 54.2996 136.549 53.8286 136.356C53.3511 136.161 52.9668 135.906 52.6757 135.592C52.3816 135.28 52.1917 134.935 52.1061 134.557C52.0196 134.181 52.0507 133.802 52.1994 133.421L53.3725 133.901Z"
          />
          <path
            d="M22.0227 77.4737L14.7606 77.081L14.8317 75.7655L20.991 76.0985L21.164 72.9001L22.2668 72.9597L22.0227 77.4737Z"
          />
          <path
            d="M15.5088 68.379L22.7038 69.4396L22.5117 70.743L15.3167 69.6824L15.5088 68.379Z"
          />
          <path
            d="M22.9963 67.1556L15.9419 65.3869L16.6052 62.7415C16.741 62.1996 16.9492 61.7685 17.2296 61.4483C17.5106 61.1258 17.8446 60.913 18.2317 60.8099C18.617 60.7039 19.0348 60.7074 19.4848 60.8202C19.9372 60.9336 20.3057 61.1285 20.5903 61.4049C20.8732 61.6784 21.0587 62.0239 21.1469 62.4414C21.2327 62.8583 21.2077 63.3377 21.0718 63.8797L20.5995 65.7638L19.5385 65.4978L19.9677 63.7859C20.0472 63.469 20.0686 63.1986 20.032 62.9746C19.9931 62.7501 19.9008 62.5683 19.7551 62.4293C19.6076 62.2873 19.4087 62.185 19.1584 62.1222C18.9081 62.0595 18.6822 62.0553 18.4806 62.1097C18.2767 62.1636 18.1035 62.28 17.961 62.4591C17.8162 62.6375 17.7038 62.8864 17.6237 63.2056L17.3301 64.3767L23.3167 65.8776L22.9963 67.1556ZM20.7135 62.7098L24.3478 61.7649L23.9903 63.1909L20.3637 64.1049L20.7135 62.7098Z"
          />
          <path
            d="M24.9662 59.7433L18.1961 57.0866L19.924 52.6834L20.9521 53.0868L19.7054 56.2636L21.5434 56.9849L22.7006 54.0362L23.7286 54.4396L22.5715 57.3883L24.4194 58.1135L25.6764 54.9102L26.7045 55.3137L24.9662 59.7433Z"
          />
          <path
            d="M28.7107 51.1027L22.4764 47.3577L23.1548 46.2283L28.4424 49.4046L30.0918 46.6588L31.0386 47.2275L28.7107 51.1027Z"
          />
          <path
            d="M27.0041 40.3044L27.5915 40.7284C27.7604 40.8503 27.9125 41.0097 28.0477 41.2066C28.1825 41.4002 28.2922 41.6123 28.3769 41.8428C28.4597 42.0719 28.5084 42.2997 28.5229 42.5263L27.8599 42.7835C27.7768 42.5629 27.6715 42.3468 27.544 42.135C27.4146 41.9219 27.2328 41.7308 26.9987 41.5618L26.4055 41.1336L27.0041 40.3044Z"
          />
          <path
            d="M33.8324 43.2198L28.2955 38.5044L31.3623 34.9032L32.2031 35.6193L29.9905 38.2174L31.4937 39.4976L33.5474 37.086L34.3883 37.802L32.3345 40.2136L33.8458 41.5007L36.0768 38.8809L36.9177 39.5969L33.8324 43.2198Z"
          />
          <path
            d="M33.7751 33.8562L33.0319 33.0393L37.3241 29.1346L38.0673 29.9515L36.4045 31.4642L40.5553 36.0269L39.5887 36.9063L35.4378 32.3435L33.7751 33.8562Z"
          />
          <path
            d="M43.3983 24.5988L44.4804 23.8473L47.1905 27.7501C47.4876 28.1779 47.6478 28.6244 47.671 29.0895C47.6962 29.5534 47.5905 30.0029 47.3538 30.4382C47.1157 30.8716 46.7536 31.257 46.2675 31.5946C45.7794 31.9335 45.2908 32.1388 44.8015 32.2105C44.311 32.2802 43.8528 32.2223 43.427 32.0366C43.0012 31.8509 42.6398 31.5442 42.3427 31.1164L39.6326 27.2137L40.7148 26.4622L43.3621 30.2745C43.5349 30.5234 43.7433 30.7073 43.9872 30.8261C44.2331 30.9436 44.4947 30.9883 44.7719 30.96C45.0478 30.9298 45.3229 30.8195 45.5971 30.6291C45.8712 30.4387 46.0707 30.2196 46.1953 29.9716C46.3206 29.7203 46.3702 29.4596 46.344 29.1896C46.3179 28.9195 46.2184 28.66 46.0456 28.4111L43.3983 24.5988Z"
          />
          <path
            d="M52.2149 27.8247L50.0227 28.9508L46.6997 22.4816L48.9362 21.3328C49.5784 21.0029 50.1967 20.849 50.7909 20.8712C51.3862 20.8901 51.9319 21.0769 52.4281 21.4315C52.9243 21.7861 53.3449 22.2993 53.69 22.971C54.0362 23.6449 54.2083 24.2884 54.2065 24.9014C54.2068 25.5134 54.0387 26.0695 53.7021 26.5698C53.3677 27.0689 52.8719 27.4873 52.2149 27.8247ZM50.6738 27.3349L51.6372 26.84C52.0879 26.6085 52.4216 26.3333 52.6383 26.0144C52.854 25.6933 52.9533 25.3296 52.9362 24.9232C52.918 24.5147 52.7818 24.063 52.5276 23.5681C52.2734 23.0732 51.9866 22.7015 51.6674 22.453C51.347 22.2024 50.9967 22.0723 50.6165 22.0626C50.2373 22.0498 49.8287 22.1559 49.3907 22.3809L48.3925 22.8936L50.6738 27.3349Z"
          />
          <path
            d="M56.8078 25.5899L54.3297 18.7524L58.7767 17.1406L59.153 18.1789L55.9446 19.3418L56.6174 21.198L59.5954 20.1187L59.9718 21.157L56.9937 22.2364L57.6701 24.1026L60.9052 22.9302L61.2815 23.9685L56.8078 25.5899Z"
          />
          <path
            d="M68.1707 22.264L65.7431 22.6887L64.49 15.5247L66.9666 15.0915C67.6778 14.9671 68.3139 15.0036 68.8748 15.2011C69.4376 15.3959 69.9033 15.7362 70.2719 16.2221C70.6405 16.708 70.8898 17.3229 71.02 18.0668C71.1505 18.8131 71.1239 19.4787 70.9402 20.0636C70.7589 20.6481 70.4333 21.1292 69.9634 21.507C69.4959 21.8844 68.8983 22.1368 68.1707 22.264ZM66.8445 21.3388L67.9114 21.1522C68.4104 21.0649 68.8108 20.9011 69.1124 20.6609C69.4137 20.4183 69.6164 20.1005 69.7207 19.7073C69.8246 19.3118 69.8286 18.84 69.7327 18.292C69.6368 17.744 69.4733 17.3039 69.2422 16.9718C69.0107 16.6374 68.7148 16.4092 68.3546 16.2871C67.9963 16.1623 67.5746 16.1424 67.0895 16.2272L65.9841 16.4206L66.8445 21.3388Z"
          />
          <path
            d="M73.2109 21.4969L72.8742 14.2319L77.5993 14.013L77.6504 15.1162L74.2414 15.2742L74.3328 17.2465L77.497 17.0999L77.5481 18.2031L74.3839 18.3497L74.4758 20.3327L77.9132 20.1734L77.9643 21.2766L73.2109 21.4969Z"
          />
          <path
            d="M89.6305 17.4085L88.3138 17.2351C88.3047 17.0142 88.2606 16.8138 88.1817 16.6338C88.103 16.4515 87.9963 16.2918 87.8615 16.1547C87.7268 16.0175 87.567 15.9069 87.3823 15.8229C87.2002 15.7368 86.9989 15.6792 86.7782 15.6502C86.3863 15.5985 86.0259 15.6513 85.6971 15.8086C85.3687 15.9635 85.0939 16.2186 84.8728 16.5739C84.652 16.9269 84.5058 17.3745 84.4344 17.9167C84.3617 18.4683 84.3862 18.9443 84.5077 19.3447C84.6319 19.7432 84.8316 20.0584 85.1066 20.2905C85.3844 20.5205 85.7181 20.6611 86.1077 20.7125C86.3236 20.7409 86.5292 20.7394 86.7244 20.7078C86.9222 20.6741 87.1037 20.6109 87.2687 20.518C87.4361 20.4254 87.5823 20.3038 87.7072 20.1531C87.8345 20.0027 87.932 19.8246 87.9997 19.6186L89.3155 19.7991C89.2211 20.1353 89.0719 20.4428 88.8681 20.7216C88.6665 21.0007 88.4192 21.2379 88.126 21.4333C87.8331 21.6263 87.5021 21.7642 87.1331 21.8469C86.7643 21.9273 86.3651 21.9391 85.9356 21.8826C85.3019 21.7991 84.7555 21.5778 84.2966 21.2189C83.8377 20.8599 83.5015 20.3846 83.2881 19.793C83.0748 19.2013 83.0191 18.5182 83.1212 17.7437C83.2235 16.9668 83.4554 16.3215 83.8168 15.808C84.1784 15.2921 84.6274 14.9202 85.1637 14.6924C85.6999 14.4646 86.2825 14.3921 86.9116 14.475C87.3129 14.5279 87.6787 14.6334 88.0089 14.7915C88.3391 14.9496 88.6248 15.1556 88.8659 15.4094C89.1074 15.6609 89.2943 15.9554 89.4265 16.2928C89.5614 16.6281 89.6294 17.0001 89.6305 17.4085Z"
          />
          <path
            d="M91.1939 22.672L89.8366 22.3042L94.2101 15.9543L95.7799 16.3797L96.3523 24.0699L94.995 23.7021L94.6323 17.614L94.5775 17.5992L91.1939 22.672ZM91.9843 19.9318L95.6861 20.9349L95.4093 21.9563L91.7075 20.9532L91.9843 19.9318Z"
          />
          <path
            d="M103.216 21.1257C103.303 20.8261 103.265 20.5497 103.103 20.2965C102.943 20.0441 102.682 19.8438 102.321 19.6957C102.067 19.5916 101.833 19.5406 101.62 19.5427C101.406 19.5449 101.225 19.5919 101.074 19.6839C100.924 19.7758 100.813 19.9057 100.742 20.0734C100.685 20.2136 100.667 20.3482 100.688 20.4772C100.711 20.6071 100.764 20.7311 100.846 20.8492C100.929 20.9651 101.029 21.0749 101.145 21.1788C101.261 21.2826 101.381 21.3793 101.506 21.4687L102.072 21.885C102.302 22.0482 102.514 22.2283 102.707 22.4251C102.902 22.6228 103.061 22.8365 103.185 23.0662C103.31 23.2967 103.383 23.5429 103.404 23.8046C103.425 24.0664 103.375 24.3429 103.256 24.6343C103.094 25.0286 102.851 25.3345 102.527 25.552C102.203 25.7674 101.816 25.8813 101.366 25.8938C100.919 25.9049 100.425 25.8001 99.8866 25.5793C99.363 25.3647 98.9417 25.0974 98.6226 24.7773C98.3056 24.4581 98.1025 24.0985 98.0131 23.6985C97.9259 23.2995 97.9651 22.8729 98.1307 22.4189L99.3301 22.9104C99.2515 23.152 99.2439 23.3765 99.3072 23.5841C99.3706 23.7917 99.4884 23.9756 99.6607 24.1358C99.8352 24.2968 100.049 24.4294 100.304 24.5336C100.569 24.6422 100.817 24.6979 101.049 24.7008C101.283 24.7023 101.487 24.6552 101.659 24.5595C101.832 24.4616 101.96 24.3167 102.041 24.1249C102.11 23.9487 102.118 23.783 102.064 23.6278C102.011 23.4704 101.909 23.3161 101.759 23.165C101.611 23.0125 101.43 22.8562 101.214 22.6961L100.529 22.1852C100.033 21.8157 99.6836 21.4204 99.48 20.9992C99.2796 20.5768 99.2782 20.1246 99.4757 19.6427C99.6381 19.2462 99.8878 18.943 100.225 18.733C100.564 18.524 100.953 18.4136 101.393 18.4019C101.833 18.3881 102.289 18.4776 102.76 18.6706C103.238 18.8664 103.622 19.1211 103.913 19.4348C104.207 19.7472 104.397 20.0924 104.482 20.4703C104.569 20.846 104.538 21.2247 104.389 21.6064L103.216 21.1257Z"
          />
        </g>
      </g>
    </template>
    <path
      class="icon"
      fill-rule="evenodd"
      clip-rule="evenodd"
      :d="icons.large[icon]"
    />
  </svg>
</template>

<script setup lang="ts">
import { PropType } from 'vue'
import { icons } from './icons'

import { useChromeStore } from '@/stores/chrome'
const chrome = useChromeStore()

defineProps({
  icon: {
    type: String as PropType<keyof typeof icons>,
    default: 'default',
  },
  text: {
    type: String,
    default: '',
  },
  repeat: {
    type: Number,
    default: 2,
  },
  offset: {
    type: Number,
    default: 25,
  },
})
</script>

<style lang="scss" scoped>
.text {
  @extend %fw-bold;

  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.path {
  transform: scale(0.85);
  transform-origin: 50%;
}
</style>
